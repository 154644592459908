import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Col, Row } from "react-bootstrap"
import styled from "styled-components"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query BlogPostsQuery {
          allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                  path
                  date
                  BlogImageAlt
                  BlogImage {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                }
                timeToRead
                id
                excerpt(pruneLength: 222)
              }
            }
            totalCount
          }
        }
      `}
      render={data => {
        const BlogTitle = styled.h3`
          font-size: 1rem;
          margin-top: 3rem;
        `
        const BlogDesc = styled.p`
          font-size: 15px;
        `
        const BlogDate = styled.p`
          font-size: 15px;
          color: #0000009c;
        `
        const Column = styled(Col)`
          max-width: 20rem;
          border: 1px solid #00000040;
          padding: 1rem;
          margin-top: 1rem;
          transition: all 0.5s ease;
          &:hover {
            border: 1px solid #74ffc28f;
            box-shadow: 1px 2px 10px 0px #74ffc28f;
            transform: scale3d(1.01, 1.01, 1.01);
          }
          @media (max-width: 600px) {
            max-width: 100%;
          }
        `
        const RowStyled = styled(Row)`
          margin-left: 0;
          margin-right: 0;
          justify-content: space-between;
        `
        const BlackLink = styled(Link)`
          color: #333333;
        `

        return (
          <Layout>
            <SEO title="Prolacs Blog" description="All about laundry 4.0" />
            <Container>
              <div className="prolacs-divider-title">
                <br></br>
                <br></br>
                <h1>Blog</h1>
              </div>
              <h2 className="controllerheadtext" style={{ width: "100%" }}>
                All About Smart Solutions for Laundry 4.0
              </h2>
              <RowStyled style={{ height: "48vh" }}>
                {data.allMarkdownRemark.edges.map(({ node }) => (
                  <BlackLink to={node.frontmatter.path} key={node.id}>
                    <Column>
                      <Img
                        fluid={node.frontmatter.BlogImage.childImageSharp.fluid}
                        alt={node.frontmatter.BlogImageAlt}
                      />
                      <BlogTitle>{node.frontmatter.title}</BlogTitle>
                      <BlogDate>{node.frontmatter.date}</BlogDate>
                      <BlogDesc>{node.excerpt}</BlogDesc>
                    </Column>
                  </BlackLink>
                ))}
              </RowStyled>
            </Container>
          </Layout>
        )
      }}
    />
  )
}

